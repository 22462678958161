<template>
  <div class="adminCreatorEditProfile">
    <Spacer num="2" />
    <form class="adminProfileEdit" v-if="creator" @submit.prevent="">
      <div class="avatar creatorCoverAvatar">
        <UserAvatar :user="creator" size="82px" />
      </div>
      <Spacer num="1" />
      <div class="creatorUserName">{{ userName }}</div>
      <Spacer num="2" />
      <div class="configInput userStatusInput">
        <span class="configInputLabel">{{ $locale["email"] }}</span>
        <span>{{ creator.email }}</span>
      </div>
      <div class="docsStatusInput">
        <Collapse :label="$locale['documents']">
          <UserIdentityDocs :user="creator" />
        </Collapse>
      </div>
      <div class="configInput userStatusInput">
        <span class="configInputLabel">{{ $locale["user_platform_active_message"] }}</span>
        <ToggleSwitchLight name="status" :values="{ on: 'active', off: 'inactive' }" :value="creator.status || 'active'" />
      </div>
      <div class="configInput userIsExclusiveInput">
        <span class="configInputLabel">{{ $locale["is_user_platform_exclusive"] }}</span>
        <ToggleSwitchLight name="exclusive" :values="{ on: 'true', off: 'false' }" :value="String(creator.exclusive) || 'false'" />
      </div>
      <div class="docsStatusInput">
        <Collapse :label="$locale['account']">
          <div dpadding>
            <Spacer num=".5" />
            <CircleButton :label="$locale['delete_user']" icon="trash" size="49px" @click="deleteUserConfirm(creator)" />
          </div>
        </Collapse>
      </div>
      <Spacer num="2" />
    </form>
    <div class="creatorLandingConfirm">
      <Confirm @cancel="modal.close()" :confirm="confirm" @accept="updateProfile" v-if="!$loading" />
      <div class="savignProgress" v-else>
        <iconic name="gspinner" />
        <span>{{ $locale["updating"] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AdminCreatorLanding from "./AdminCreatorLanding.vue";
export default {
  mixins: [AdminCreatorLanding],
  components: {
    UserIdentityDocs: () => import("../user/UserIdentityDocs.vue"),
  },
  methods: {
    updateProfile: async function() {
      this.isLoading(true);
      try {
        const formData = new FormData(document.querySelector(".adminProfileEdit"));
        const query = `?creator=${this.userName}`;
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}admin/creatorProfileEdit${query}`, formData);
        this.responseUpdate(response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    responseUpdate: function(data) {
      if (!data.success) {
        this.ialert.go({ title: "Error", message: data.message });
        return;
      }

      this.ialert.go({ title: this.$locale["success"], message: data.message });
    },
    deleteUserConfirm: function(creator) {
      this.view({
        user: creator,
        title: this.$locale["delete_user"],
        closeOutside: true,
        component: () => import("./AdminUserDelete.vue"),
        onDelete: this.modal.close,
      });
    },
  },
};
</script>

<style lang="scss">
.adminCreatorEditProfile {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .adminProfileEdit {
    text-align: center;
    padding: $mpadding * 1.5;
    .user-avatar {
      margin: auto;
    }
    .userStatusInput {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $mpadding * 2;
    }
  }

  .creatorLandingConfirm {
    padding: $mpadding;
    margin: auto 0 0 0;
  }

  .adminProfileEdit .configInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $mpadding;
    width: 100%;
    padding: $mpadding * 1.5 0;
    border-bottom: solid 1px $alto;
  }

  .adminProfileEdit .configInputLabel {
    display: block;
  }

  .docsStatusInput .collapse-header {
    padding: $mpadding 0;
    border-bottom: solid 1px $alto;
  }

  .docsStatusInput .collapse.open .collapse-content {
    border-bottom: solid 1px $alto;
  }

  .docsStatusInput .collapse .user-documents {
    margin: $mpadding 0 $mpadding 0;
  }
}
</style>
